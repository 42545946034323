.contactwrap{
    margin-top: 9vh;
    text-align: center;
}

.doublebutton{
    display: flex;
    flex-direction: row;
    height: 10vh;
    padding-left: 9.5vw;
    margin-top: 3vh;
    padding-top: 1vh;
    gap: 1vw;
}

.button1{
    background-color:  #1DA1F2;
    width: 40vw;
    height: 5vh;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 70px);
    border: 2px solid #1DA1F2;
    cursor: pointer;
    border-radius: 1vw;
}

.button2{
    width: 40vw;
    height: 5vh;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 70px);
    cursor: pointer;
    border-radius: 1vw;
    background-color: #FF6969;
    color:white;
    border: 2px solid #FF6969;
}

.button1:hover, .button2:hover{
    background-color:  white;
    color: #444444;
    border: 2px solid #444444;
    transition: all .3s ease-in-out;
}


.credit{
    font-size: clamp(10px, 3vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    font-weight: bold;
    text-align: center;
}

.iconHolderCredit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:4vw;
    margin-top: 2vh;
    margin-bottom: 4vh;
}

.iconcredit {
    cursor: pointer;
    transform: scale(1.3);
}


@media screen and (min-width: 769px){
    .contactwrap{
        margin-top: 9vh;
        text-align: center;
    }
    
    .doublebutton{
        margin-top:0vh;
        padding: 0;
        justify-content: center;
        gap: 0.2vw;
    }
    
    .button1{
        width: 25vw;
        height: 8vh;
        font-size: clamp(15px, 2.5vw, 25px);
        border-radius: 0.5vw;
    }
    
    .button2{
        width: 25vw;
        height: 8vh;
        font-size: clamp(15px, 2.5vw, 25px);
        border-radius: 0.5vw;
    }

    .credits{
        margin-top: 2vh;
    }

    .credit{
        font-size: clamp(15px, 1.3vw, 25px);
    }
    
    .iconHolderCredit {
        margin-top: 3vh;
    }
    
    .iconcredit {
        transform: scale(2);
    }
    .iconcredit:hover {
        transform: scale(2.5);
        transition: all .3s ease-in-out;
    }
    
}

@media screen and (min-width: 1525px){
    
    .doublebutton{
        margin-top:2vh;
        margin-bottom:2vh;
        padding: 0;
        justify-content: center;
        gap: 0.1vw;
    }
    
    .button1{
        width: 15vw;
        font-size: clamp(15px, 2vw, 20px);
        border-radius: 0.2vw;
    }
    
    .button2{
        width: 15vw;
        font-size: clamp(15px, 2vw, 20px);
        border-radius: 0.2vw;
    }

    .credits{
        margin-top: 0vh;
    }

    .credit{
        font-size: clamp(15px, 1vw, 25px);
    }
    
    .iconHolderCredit {
        margin-top: 5vh;
    }
    
    .iconcredit {
        transform: scale(2);
    }
}
@media screen and (min-width: 2000px){
    .credit{
        font-size: clamp(15px, 0.8vw, 25px);
    }
}