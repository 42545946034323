.sectiontitle{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 30px);
    color: #4267B2;
    margin-top: 2vh;
    margin-bottom:1vh;
    padding-left: 10vw;
    padding-right:10vw;
}

.sectioninfo, .sectioninfo2{
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: clamp(10px, 3.5vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    padding-bottom: 4vh;
    font-weight: bold;
}

.sectiontitle2{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 30px);
    background-color: #F5F5F5;
    padding-bottom: 2vh;
    color: #4267B2;
    padding-top: 2vh;
}
.sectioninfo2{
    background-color: #F5F5F5;
    color: #444444;
}

@media screen and (min-width: 992px){
    .sectiontitle, .sectiontitle2{
        padding-bottom: 5vh;
        padding-top: 10vh;
    }
    .sectioninfo,.sectioninfo2{
        padding-left: 20vw;
        padding-right: 20vw;
        padding-bottom: 10vh;
    }
}