.option{
    font-size: clamp(13px, 3vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    font-weight: bold;
}
.option:hover{
    transform: scale(1.2);
    transition: all .3s ease-in-out;
}

.removea{
    text-decoration: none;
}
@media screen and (min-width: 769px) {
    .option::before {
      height: 2px;
      content: '';
      position: absolute;
      width: 100%;
      background-color: #444444;
      bottom: -2px;
      left: 0;
      transform: scaleX(0);
      transition: transform .3s ease-in-out;
    }

    .option:hover::before {
      transform: scaleX(1);
    }
}
