p{
    margin: 0;
}

.Title{
    margin-top: 25vh;
}

.TitleBack{
    height: 94vh;
    width: 100%;
    margin-top: 0;
    background-color: #F5F5F5;
    padding-top: 6vh;
}
.TitleAss{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 30px);
}
.TitleWom{
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(30px, 8vw, 40px);
}
.TitlePul{
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(30px, 8vw, 40px);
}

.iconHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    margin-top: 5rem;
}
.TitlePul2{
    display:none;
}

.icon {
    cursor: pointer;
}
.icon:hover {
    transform: scale(1.2);
    transition: all .3s ease-in-out;
}

@media screen and (min-width: 769px) {
    @keyframes slideleft{
        from{
            opacity : 0;
        }
        to{
            opacity: 1;
        }
    }
    
    .titlesplit{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .TitleWom{
        display:none;
    }
    .TitlePul{
        display:none;
    }
    .TitlePul2{
        animation: slideleft 4s;
        text-align: center;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        font-size: clamp(30px, 4vw, 60px);   
        display: block;     
    }
    .icon{
        transform: scale(1.5);
    }
    .icon:hover {
        transform: scale(2);
        transition: all .3s ease-in-out;
    }
    .iconHolder{
        gap: 10rem;
    }
}
