.cardHolder{
    text-align: center;
    border-radius: 7%;
    padding-top: 7%;
    position: relative;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
    /* box-shadow: 1px 1px 10px rgba(66, 103, 178, 0.3); */
    background: radial-gradient(220% 91% at 0% 0%,
    rgba(68, 68, 68, 0.2) 50%,
    transparent 51%);
    /* background: radial-gradient(220% 91% at 0% 0%,
    rgba(66, 103, 178, 0.4) 50%,
    transparent 51%); */
    overflow:hidden
}

.image{
    width: 40%;
    aspect-ratio : 1 / 1;
    border-radius: 50%;
    margin-bottom: 5%;
    padding: 1%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}

.circle-1 {
    display: none;
    position: absolute;
    box-sizing: border-box;
    width: 43%;
    aspect-ratio : 1 / 1;
    margin-left:28.5%;
    top: 6.8%;
    border-width: 2px;
    border-style: solid;
    border-color: white transparent rgba(68, 68, 68, 0.7) white ;
    border-radius: 50%;
    transition: all 1.5s ease-in-out;
}

.name{
    font-size: clamp(10px, 4vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    font-weight: bold;
}

.role{
    font-size: clamp(10px, 3.8vw, 30px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    padding-top: 2%;
    font-weight: bold;
    margin-bottom: 2%;
}
.social{
    margin:0;
    padding: 0;
    padding-top: 3.5%;
    padding-bottom: 3.5%;
    width: 100%;
    margin-top: 5%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.iconCard{
    color: #4267B2;
    transform: scale(1.2);
    cursor: pointer;
}
.iconCard2{
    color: #FF6969;
    transform: scale(1.2);
    cursor: pointer;
}

@media (min-width: 500px) {
    .name{
        font-size: clamp(10px, 3vw, 20px);
    }
    
    .role{
        font-size: clamp(10px, 2.5vw, 20px);
    }

}


@media screen and (min-width: 769px){

    .name{
        font-size: clamp(10px, 2vw, 20px);
    }
    
    .role{
        font-size: clamp(10px, 1.5vw, 20px);
    }
    .circle-1{
        display:block;
    }
    .image{
        box-shadow: none;
    }
    .socialHold{
        transform:translateY(110%);
        /* background-color: rgba(66, 103, 178, 0.9); */
        background-color: rgba(68, 68, 68, 0.05);
        /* background-color: rgba(245, 245, 245, 1); */
        transition: all .3s ease-in-out;
    }
    .cardHolder:hover .socialHold{
        transform: translateY(0);
    }
    .cardHolder:hover .circle-1{
        transform: rotate(-270deg);
        transition-duration: 1s;
    }
    .cardHolder:hover{
        transform: scale(1.1);
        background: radial-gradient(220% 91% at 0% 0%,
        rgba(68, 68, 68, 0.5) 50%,
        transparent 51%);
        transition: all .3s ease-in-out;
    }
    
    .iconexpand:hover .iconCard{
        transform: scale(1.5);
        transition: all .3s ease-in-out;
    }
    .iconexpand2:hover .iconCard2{
        transform: scale(1.5);
        transition: all .3s ease-in-out;
    }

}

@media screen and (min-width: 1000px){
    .circle-1 {
        width: 43%;
        margin-left:28.5%;
        top: 6%;
    }       
    .role{
        font-size: clamp(10px, 1.2vw, 20px);
    }
}
@media screen and (min-width: 1250px){
    .circle-1 {
        width: 43%;
        margin-left:28.5%;
        top: 6.5%;
    } 
}
@media screen and (min-width: 1525px){
    .role{
        font-size: clamp(10px, 1vw, 20px);
    }
}
@media screen and (min-width: 2000px){
    .role{
        font-size: clamp(10px, 0.8vw, 20px);
    }
}