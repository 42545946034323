.titleback{
    padding-top: 5vh;
    padding-bottom: 4vh;
    background-color: #F5F5F5;
}

.title{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: clamp(15px, 3vw, 30px);
    position:relative;
}

.title:before{
    height: 0.2vh;
    width: 70vw;
    background-color: black;
    content: '';
    display:block;
    position:absolute;
    margin-left: 15vw;
    border-radius: 2vh;
    top: -3vh;
    opacity: 20%;
}

.aboutinfo{
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 7vh;
    font-size: clamp(10px, 3.5vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #444444;
    padding-bottom: 7vh;
    font-weight: bold;
}

.read{
    text-align: center;
    font-size: clamp(13px, 3vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #1DA1F2;
    font-weight: bold;
}

.hov:hover{
    transform: scale(1.1);
    transition: all .3s ease-in-out;
}

@media screen and (min-width: 769px) {
    .titleback{
        padding-top: 6vh;
        padding-bottom: 6vh;
    }
    
    .title{
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
    
    .title:before{
        width: 50vw;
        margin-left: 25vw;
    }
    
    .aboutinfo{
        margin-left: 20vw;
        margin-right: 20vw;
        padding-top: 10vh;
        padding-top: 10vh;
    }
}