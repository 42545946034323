.AboutPageHeader{
    padding-top: 9vh; 
    padding-bottom: 5vh;
    background-color: #F5F5F5;
}
.sectionholder, .conclusionholder{
    text-align: center;
}
.aboutop{
    font-size: clamp(13px, 3vw, 20px);
    font-family: 'Roboto', sans-serif;
    color: #4267B2;
    font-weight: bold;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
}
.aboutparral{
    display: flex;
    flex-direction: row;
    /* background-color: #F5F5F5; */
    text-align: center;
    justify-content: space-around;
    /* gap: 10vw;
    padding-left: 40vw; */
}

@media screen and (min-width: 992px){
    .aboutparral{
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 20vw;
        padding-right: 20vw;
    }
}