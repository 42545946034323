.meetHolder{
    padding-top: 9vh;
    background-color: #F5F5F5;
    padding-bottom: 9vh;
}

.cards{
    /* background-color: #F5F5F5; */
    margin-top: 5vh;
    display:grid;
    gap: 2rem;
    margin-left:20vw;
    margin-right: 20vw;
}

@media (min-width: 500px) {
    .cards { grid-template-columns: repeat(2, 1fr);
        margin-left: 10vw;
        margin-right: 10vw; }

}


@media screen and (min-width: 769px){
    .meetHolder{
        padding-top:15vh;
    }
    .cards { grid-template-columns: repeat(3, 1fr);
        margin-left: 10vw;
        margin-right: 10vw; }

}

@media screen and (min-width: 1000px){
    .meetHolder{
        padding-top:15vh;
    }
    .cards { grid-template-columns: repeat(4, 1fr);
        margin-left: 10vw;
        margin-right: 10vw; }

}
@media screen and (min-width: 1525px){
    .meetHolder{
        padding-top:15vh;
    }
    .cards { grid-template-columns: repeat(5, 1fr);
        margin-left: 10vw;
        margin-right: 10vw; }

}
@media screen and (min-width: 2000px){
    .meetHolder{
        padding-top:15vh;
    }
    .cards { grid-template-columns: repeat(6, 1fr);
        margin-left: 10vw;
        margin-right: 10vw; }

}